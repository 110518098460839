export function loadClarityScript(clarityId: string): void {
  const clarityFunctionName = 'clarity'

  // Check if the global object already has the Clarity function defined
  if (!(clarityFunctionName in window)) {
    ;(window as any)[clarityFunctionName] = (...args: any[]) => {
      ;((window as any)[clarityFunctionName].q = (window as any)[clarityFunctionName].q || []).push(args)
    }
  }

  // Create a new script element
  const scriptElement = document.createElement('script')
  scriptElement.async = true
  scriptElement.src = `https://www.clarity.ms/tag/${clarityId}`

  // Append the script to the DOM
  const firstScript = document.getElementsByTagName('script')[0]
  if (firstScript && firstScript.parentNode) {
    firstScript.parentNode.insertBefore(scriptElement, firstScript)
  }
}
