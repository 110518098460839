import { createApp } from 'vue'
import { createPinia } from 'pinia'
import { createVuetify } from 'vuetify'
import { VueQueryPlugin } from '@tanstack/vue-query'
import { aliases, mdi } from 'vuetify/iconsets/mdi'
import VueGtag from 'vue-gtag'
import { createVPhoneInput } from 'v-phone-input'

import '@mdi/font/css/materialdesignicons.css'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'vuetify/styles'
import 'dayjs/locale/es'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import 'flag-icons/css/flag-icons.min.css'
import 'v-phone-input/dist/v-phone-input.css'

import App from './App.vue'
import router from './router'
import './assets/main.css'

import { AxiosInterceptor } from './interceptors/axios.interceptor'

AxiosInterceptor()

const vuetify = createVuetify({
  icons: {
    defaultSet: 'mdi',
    aliases,
    sets: {
      mdi,
    },
  },
  components,
  directives,
})

const app = createApp(App)

const vueQueryPluginOptions = {
  queryClientConfig: {
    defaultOptions: {
      queries: {
        refetchOnWindowFocus: false,
      },
    },
  },
}

app.use(VueGtag, {
  config: { id: import.meta.env.VUE_GOOGLE_ANALYTICS },
})

const vPhoneInput = createVPhoneInput({
  countryIconMode: 'svg',
})

app.use(VueQueryPlugin, vueQueryPluginOptions)
app.use(createPinia())
app.use(vuetify)
app.use(router)
app.use(vPhoneInput)

Sentry.init({
  app,
  environment: import.meta.env.VITE_SENTRY_ENV,
  dsn: import.meta.env.VITE_SENTRY_DSN,
  release: `${'partners'}@${import.meta.env.VITE_SENTRY_RELEASE ?? '0.0.0'}`,
  integrations: [
    new Integrations.BrowserTracing(),
    new Sentry.Replay({
      maskAllText: false,
    }),
  ],
  debug: import.meta.env.VITE_SENTRY_ENV !== 'production',
  tracesSampleRate: import.meta.env.VITE_SENTRY_ENV === 'production' ? 0.3 : 1,
  replaysOnErrorSampleRate: 1,
  logErrors: import.meta.env.VITE_SENTRY_ENV !== 'production',
})

app.mount('#app')
