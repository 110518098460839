<script setup lang="ts">
import { RouterView } from 'vue-router'
import { loadClarityScript } from '@/lib/clarity'

const clarityId = import.meta.env.VITE_APP_CLARITY_ID
if (clarityId) {
  loadClarityScript(clarityId)
}
</script>

<template>
  <v-app>
    <RouterView />
  </v-app>
</template>

<style scoped></style>
