import { defineStore } from 'pinia'
import { computed, ref, watchEffect } from 'vue'
import { useStorage } from '@vueuse/core'
import router from '@/router'

import type { User } from '../types'
import { userLogin, type LoginParams } from '@/services/auth.services'
import { createOrganizationAdapter } from '../adapters'

export const useAuthStore = defineStore('auth', () => {
  const isAuthenticated = ref(false)
  const user = useStorage('user', {} as User)
  const token = useStorage('token', '')
  const INSURANCE = 'insurance'

  const organization = computed(() => {
    return createOrganizationAdapter(user.value.organization)
  })

  const isAnInsurance = computed(() => {
    return organization.value?.extraData.some((item) => item === INSURANCE)
  })

  watchEffect(() => {
    if (token.value) {
      isAuthenticated.value = true
    }
  })

  async function login(params: LoginParams) {
    const { data } = await userLogin(params)
    user.value = data
    token.value = data.token
    router.push('/')
  }

  return { isAuthenticated, user, token, organization, isAnInsurance, login }
})
