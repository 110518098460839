import { useAuthStore } from '@/stores/auth'
import { storeToRefs } from 'pinia'
import { createRouter, createWebHistory } from 'vue-router'
import { usePermissions } from '../stores/permissions'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      name: 'main-layout',
      redirect: '/inicio',
      // redirect() {
      //   const permissionStore = usePermissions()
      //   const { userPermissions } = storeToRefs(permissionStore)

      //   if (userPermissions.value.canSeeAllRequests) {
      //     return { name: 'requests' }
      //   } else {
      //     return { name: 'my-requests' }
      //   }
      // },
      component: () => import('@/layouts/DefaultLayout.vue'),
      meta: { title: 'Inicio' },
      children: [
        {
          path: '/inicio',
          name: 'home',
          component: () => import('@/views/HomeView.vue'),
          meta: { title: 'Inicio' },
        },
        {
          path: '/pedidos',
          name: 'requests',
          component: () => import('@/views/RequestsView.vue'),
          meta: { title: 'Pedidos', isAdmin: true },
        },
        {
          path: '/mis-pedidos',
          name: 'my-requests',
          component: () => import('@/views/MyRequestsView.vue'),
          meta: { title: 'Mis pedidos' },
        },
        {
          path: '/crear-asistencia',
          name: 'create-assist',
          component: () => import('@/views/CreateAssist.vue'),
          meta: { title: 'Nueva asistencia' },
        },
        {
          path: '/crear-siniestro',
          name: 'create-order',
          component: () => import('@/views/CreateOrder.vue'),
          meta: { title: 'Nuevo siniestro' },
        },
        {
          path: '/crear-cliente',
          name: 'create-client',
          component: () => import('@/views/CreateClient.vue'),
          meta: { title: 'Nuevo cliente' },
        },
        {
          path: '/pedido/:id',
          name: 'order-detail',
          component: () => import('@/views/OrderDetailView.vue'),
          meta: { title: 'Detalle del pedido' },
          props: true,
        },
        {
          path: '/estadisticas',
          name: 'statistics',
          component: () => import('@/views/StatisticsView.vue'),
          meta: { title: 'Estadísticas' },
        },
        {
          path: '/nueva-venta',
          name: 'new-sales',
          component: () => import('@/views/CreateSaleView.vue'),
          meta: { title: 'Nueva venta' },
        },
        {
          path: '/mis-clientes',
          name: 'my-sales',
          component: () => import('@/views/MyClientsView.vue'),
          meta: { title: 'Mis Clientes' },
        },
        {
          path: '/servicios',
          name: 'services',
          component: () => import('@/views/ServiceView.vue'),
          meta: { title: 'Servicios' },
        },
        {
          path: '/detalle-cliente/:id',
          name: 'client-detail',
          component: () => import('@/views/ClientDetailView.vue'),
          meta: { title: 'Detalle del cliente' },
          props: true,
        },
        // {
        //   path: '/facturacion',
        //   component: () => import('@/layouts/BillingLayout.vue'),
        //   children: [
        //     {
        //       path: '',
        //       name: 'billing',
        //       component: () => import('@/views/billing/BillingView.vue'),
        //     },
        //     {
        //       path: '/facturacion/:period',
        //       name: 'invoice-detail',
        //       component: () => import('@/views/billing/InvoiceDetailView.vue'),
        //       props: true,
        //     },
        //     {
        //       path: '/facturacion/formulario',
        //       name: 'invoice-form',
        //       component: () => import('@/views/billing/InvoiceFormView.vue'),
        //     },
        //     {
        //       path: '/facturacion/formulario-exito',
        //       name: 'success-invoice',
        //       component: () => import('@/views/billing/InvoiceSuccessView.vue'),
        //     },
        //     {
        //       path: '/facturacion/formulario-error',
        //       name: 'error-invoice',
        //       component: () => import('@/views/billing/InvoiceErrorView.vue'),
        //     },
        //     {
        //       path: '/facturacion/factura-invalida',
        //       name: 'invalid-invoice',
        //       component: () => import('@/views/billing/InvoiceInvalidView.vue'),
        //     },
        //   ],
        //   meta: { title: 'Facturación' },
        // },
        {
          path: '/:pathMatch(.*)*',
          name: 'not-found',
          component: () => import('@/views/NotFoundView.vue'),
          meta: { title: 'Página no encontrada' },
        },
      ],
    },
    {
      path: '/guia-de-ayuda',
      name: 'help-guide-layout',
      component: () => import('@/layouts/HelpGuideLayout.vue'),
      meta: { title: 'Guía de ayuda' },
      children: [
        {
          path: '/guia-de-ayuda',
          name: 'help-guide',
          component: () => import('@/views/help/HelpGuide.vue'),
          meta: { title: 'Guía de ayuda' },
        },
        {
          path: '/guia-pedidos',
          name: 'help-requests',
          component: () => import('@/views/help/HelpAllRequestsView.vue'),
          meta: { title: 'Guía Pedidos', isAdmin: true },
        },
        {
          path: '/guia-mis-pedidos',
          name: 'help-my-requests',
          component: () => import('@/views/help/HelpMyRequestsView.vue'),
          meta: { title: 'Guía mis Pedidos', isAdmin: true },
        },
        {
          path: '/guia-pedido',
          name: 'help-request',
          component: () => import('@/views/help/HelpRequestView.vue'),
          meta: { title: 'Guía Pedido', isAdmin: true },
        },
        {
          path: '/guia-chat',
          name: 'help-chat',
          component: () => import('@/views/help/HelpChat.vue'),
          meta: { title: 'Guía Chat', isAdmin: true },
        },
        {
          path: '/guia-crear-asistencia',
          name: 'help-create-assistance',
          component: () => import('@/views/help/HelpCreateAssistanceView.vue'),
          meta: { title: 'Guía Crear Asistencia', isAdmin: true },
        },
        {
          path: '/guia-crear-siniestro',
          name: 'help-create-order',
          component: () => import('@/views/help/HelpCreateOrderView.vue'),
          meta: { title: 'Guía Crear Siniestro', isAdmin: true },
        },
        {
          path: '/guia-crear-cliente',
          name: 'help-create-client',
          component: () => import('@/views/help/HelpCreateClientView.vue'),
          meta: { title: 'Guía Cliente', isAdmin: true },
        },
        {
          path: '/guia-estadisticas',
          name: 'help-statistics',
          component: () => import('@/views/help/HelpStatisticView.vue'),
          meta: { title: 'Guía Estadísticas', isAdmin: true },
        },
      ],
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('@/views/LoginView.vue'),
      meta: { title: 'Rapihogar - Login' },
    },
  ],
  scrollBehavior() {
    return { top: 0, behavior: 'smooth' }
  },
})

router.beforeEach((to) => {
  document.title = `${to.meta.title}`
  const authStore = useAuthStore()
  const { isAuthenticated } = authStore
  const permissionStore = usePermissions()
  const { userPermissions } = storeToRefs(permissionStore)

  if (!isAuthenticated && to.name !== 'login') return { name: 'login' }
  if (!userPermissions.value.canSeeAllRequests && to.name === 'requests' && to.meta.isAdmin) {
    return { name: 'my-requests' }
  }

  const allowedRoutes = [
    { name: 'create-order', condition: userPermissions.value.canCreateRequests },
    { name: 'create-client', condition: userPermissions.value.canAddClient },
    { name: 'create-assist', condition: userPermissions.value.canCreateAssistanceRequests },
    { name: 'create-order', condition: userPermissions.value.canCreateSiniestrosRequests },
    { name: 'statistics', condition: userPermissions.value.canSeeStatistics },
    { name: 'new-sales', condition: userPermissions.value.canCreateSubscription },
    { name: 'my-sales', condition: userPermissions.value.canSeeSubscription },
    { name: 'services', condition: userPermissions.value.canSeeSubscription },
    { name: 'billing', condition: userPermissions.value.canSeeSubscription },
  ]

  for (const route of allowedRoutes) {
    if (to.name === route.name && !route.condition) {
      return { name: 'my-requests' }
    }
  }

  const allowedHelpRoutes = [
    { name: 'help-requests', condition: userPermissions.value.canSeeAllRequests },
    { name: 'help-chat', condition: userPermissions.value.canSeeChatRequests },
    { name: 'help-create-order', condition: userPermissions.value.canCreateRequests },
    { name: 'help-create-assistance', condition: userPermissions.value.canCreateRequests },
    { name: 'help-create-client', condition: userPermissions.value.canAddClient },
    { name: 'help-statistics', condition: userPermissions.value.canSeeStatistics },
  ]

  for (const route of allowedHelpRoutes) {
    if (to.name === route.name && !route.condition) {
      return { name: 'help-guide' }
    }
  }
})

export default router
