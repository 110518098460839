import { axiosInstance as axios } from '@/services'
import type { User } from '../types'

export type LoginParams = {
  email: string
  password: string
}

export type LostPasswordParams = {
  email: string
}

export const userLogin = (params: LoginParams): Promise<{ data: User }> => {
  return axios.post(`/login/`, params)
}

export const sendLostPasswordEmail = (params: LostPasswordParams) => {
  return axios.post('/lost_password/', params)
}
