import { acceptHMRUpdate, defineStore, storeToRefs } from 'pinia'
import { computed } from 'vue'
import { useAuthStore } from './auth'

const permissions = {
  canCreateRequests: 'RapiHogarWeb.add_pedido',
  canCreateAssistanceRequests: 'RapiHogarWeb.can_create_corpo_assistance_request',
  canSeeAllRequests: 'RapiHogarWeb.view_all_requests_of_my_organization',
  canSeeMyRequests: 'RapiHogarWeb.view_only_my_requests',
  canSeeChatRequests: 'RapiHogarWeb.view_chat',
  canSeeInboxNotification: 'RapiHogarWeb.view_notification',
  canChangeRequestResponsible: 'RapiHogarWeb.assign_a_user_to_the_request',
  canHandleBudgets: 'approve_budgets',
  canSeeOnlyAssists: 'RapiHogarWeb.view_only_assists',
  canSeeOnlyClaims: 'RapiHogarWeb.view_only_siniestros',
  canAddClient: 'RapiHogarWeb.add_cliente',
  canSeeStatistics: 'RapiHogarWeb.can_see_statistics',
  canSeeAssistanceChat: 'RapiHogarWeb.can_see_the_chat_for_asistencia_scheme',
  canSeeClaimChat: 'RapiHogarWeb.can_see_the_chat_for_siniestros_scheme',
  canSeeRefundChat: 'RapiHogarWeb.can_see_the_chat_for_reintegro_scheme',
  canCreateSubscription: 'RapiHogarWeb.add_subscription',
  canSeeSubscription: 'RapiHogarWeb.view_subscription',
  canCreateSiniestrosRequests: 'RapiHogarWeb.can_create_corpo_siniestro_request',
} as const

type PermissionsType = keyof typeof permissions

export const usePermissions = defineStore('permissions', () => {
  const auth = useAuthStore()
  const { user, organization } = storeToRefs(auth)

  const currentUserPermissions = computed(() => {
    return user.value.permissions ?? []
  })

  const userPermissions = computed(() => {
    const tempPermissions: Record<PermissionsType, boolean> = {
      canCreateRequests:
        searchPermission(currentUserPermissions.value, permissions.canCreateRequests) &&
        !!organization.value?.canCreateRequest,
      canCreateAssistanceRequests: searchPermission(
        currentUserPermissions.value,
        permissions.canCreateAssistanceRequests
      ),
      canChangeRequestResponsible: searchPermission(
        currentUserPermissions.value,
        permissions.canChangeRequestResponsible
      ),
      canHandleBudgets: searchPermission(currentUserPermissions.value, permissions.canHandleBudgets),
      canSeeAllRequests: searchPermission(currentUserPermissions.value, permissions.canSeeAllRequests),
      canSeeMyRequests: searchPermission(currentUserPermissions.value, permissions.canSeeMyRequests),
      canSeeChatRequests: searchPermission(currentUserPermissions.value, permissions.canSeeChatRequests),
      canSeeInboxNotification: searchPermission(
        currentUserPermissions.value,
        permissions.canSeeInboxNotification
      ),
      canSeeOnlyAssists: searchPermission(currentUserPermissions.value, permissions.canSeeOnlyAssists),
      canSeeOnlyClaims: searchPermission(currentUserPermissions.value, permissions.canSeeOnlyClaims),
      canAddClient: searchPermission(currentUserPermissions.value, permissions.canAddClient),
      canSeeStatistics:
        searchPermission(currentUserPermissions.value, permissions.canSeeStatistics) &&
        !!organization.value?.showCorpoStats,
      canSeeAssistanceChat: searchPermission(currentUserPermissions.value, permissions.canSeeAssistanceChat),
      canSeeClaimChat: searchPermission(currentUserPermissions.value, permissions.canSeeClaimChat),
      canSeeRefundChat: searchPermission(currentUserPermissions.value, permissions.canSeeRefundChat),
      canCreateSubscription: searchPermission(
        currentUserPermissions.value,
        permissions.canCreateSubscription
      ),
      canSeeSubscription: searchPermission(currentUserPermissions.value, permissions.canSeeSubscription),
      canCreateSiniestrosRequests: searchPermission(
        currentUserPermissions.value,
        permissions.canCreateSiniestrosRequests
      ),
    }
    return tempPermissions
  })

  return {
    userPermissions,
  }
})

function searchPermission(permissionList: string[], permission: string) {
  const permissionsInLowerCase = permissionList.map((p) => {
    return p.toLowerCase()
  })
  return permissionsInLowerCase.includes(permission.toLowerCase())
}

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(usePermissions, import.meta.hot))
}
